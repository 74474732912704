<script setup>
const nuxtApp = useNuxtApp();

const props = defineProps(['modalId']);

const loading = ref(false);
const slotRef = ref(null);

const showForce = ref(false);

if (!import.meta.client) {
    showForce.value = true;
}


nuxtApp.hook("page:start", () => {
    loading.value = true;
});
nuxtApp.hook("page:finish", () => {
    loading.value = false;

    if (process.client) {
        //     if (clientHydrating) {
        setTimeout(() => {
            if (slotRef.value) {
                slotRef.value.classList.add("show");
            }
        }, 50);
        //     }
    } else {
        if (slotRef.value) {
            slotRef.value.classList.add("show");
        }
    }
});

nuxtApp.$modalsBus.$on(`modals.${props.modalId}:close`, () => {
    close();
});

const close = () => {
    useRouter().replace('/');
}

</script>

<template>
    <DModal @close="close" class="product-modal">

        <div v-if="loading" class="d-modal-loader">
            <div class="spinner-border text-white" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div :class="{ show: false, 'show-s': showForce }" class="d-modal-anim" ref="slotRef">
            <slot />
        </div>
    </DModal>
</template>
